import { useQuery } from '@tanstack/react-query'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

import axiosInstance from '@/shared/utils/axios'

import { IProfile, IProfileResponse } from '../types'

export const fetchProfile = async () => {
  console.log('~~~~~~ window', window.apiEndpoint)
  console.log('~~~~~~ process', process.env.NEXT_PUBLIC_API_ENDPOINT)
  const resp = await axiosInstance.get<IProfileResponse>(`${process.env.API_ENDPOINT}/me`)
  return convertCaseList(resp.data, ConvertType.CAMEL) as IProfile
}

export const useProfileQuery = () => {
  return useQuery({
    queryKey: ['profile'],
    queryFn: () => fetchProfile(),
    initialData: null,
  })
}
