import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import QueryString from 'qs'
import { getCookie } from './cookies'

export interface IRequestConfig extends AxiosRequestConfig {
  _retry: boolean
}

const axiosInstance = axios.create({
  baseURL: process.env.api_endpoint,
})

axiosInstance.interceptors.request.use(
  function (config) {
    const blackList = ['/my', '/me', 'applies', 'certify']
    if (blackList.some(black => config?.url?.includes(black))) {
      const accessToken = getCookie('access_token')
      if (!accessToken) {
        return Promise.reject('No Token')
      }
      config.headers.authorization = `Bearer ${accessToken}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error: AxiosError) {
    const tokenError = new CustomEvent('tokenError', { detail: { error: true } })
    const originalRequest = error.config as IRequestConfig

    // 401 Unauthorized 발생 시 refresh_token으로 access_token 재발급 요청
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        // refresh_token으로 access_token 갱신 요청
        const {
          data: { access_token },
        } = await axios({
          method: 'POST',
          url: '/api/oauth/token', // 서버로 refresh_token 요청
          data: QueryString.stringify({
            grantType: 'refresh_token',
          }),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

        // 새로 발급된 access_token으로 요청 재시도
        originalRequest.headers = originalRequest.headers || {}
        originalRequest.headers.authorization = `Bearer ${access_token as string}`

        return axiosInstance(originalRequest)
      } catch (err) {
        // 갱신 실패 시 토큰 에러 이벤트 발생
        window.dispatchEvent(tokenError)
        return Promise.reject(error)
      }
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
