'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactNode, useEffect, useState } from 'react'
import AuthProvider from './Auth'
import axios from 'axios'
import qs from 'qs'
import Loading from '@/components/Loading'

const Providers = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await axios.get('/api/config').then(response => response.data)
        console.log('provider config:', config)
        console.log('oauthAuthorizationEndpoint:', config.oauthAuthorizationEndpoint)
        if (config) {
          if (typeof window.nodeEnv === 'undefined') window.nodeEnv = config.node_env
          if (typeof window.baseUrl === 'undefined') window.baseUrl = config.base_url
          if (typeof window.homeUrl === 'undefined') window.baseUrl = config.home_url
          if (typeof window.apiEndpoint === 'undefined') window.apiEndpoint = config.api_endpoint
          if (typeof window.oauthRegisterEndpoint === 'undefined')
            window.oauthRegisterEndpoint = config.oauth_register_endpoint
          if (typeof window.oauthLogoutEndpoint === 'undefined')
            window.oauthLogoutEndpoint = config.oauth_logout_endpoint
          if (typeof window.channelTalkKey === 'undefined')
            window.channelTalkKey = config.channel_talk_key
          if (typeof window.s3PresignedUrl === 'undefined')
            window.s3PresignedUrl = config.s3_presigned_url
          if (typeof window.s3BucketName === 'undefined')
            window.s3BucketName = config.s3_bucket_name
          if (typeof window.kakaoShare === 'undefined') window.kakaoShare = config.kakao_share
          if (typeof window.cookieDomain === 'undefined') window.cookieDomain = config.cookie_domain
          if (typeof window.bebridgeChatbotUrl === 'undefined')
            window.bebridgeChatbotUrl = config.bebridge_chatbot_url
          if (typeof window.bebridgeChatbotPluginKey === 'undefined')
            window.bebridgeChatbotPluginKey = config.bebridge_chatbot_plugin_key
          if (typeof window.bebridgeChatbotBotKey === 'undefined')
            window.bebridgeChatbotBotKey = config.bebridge_chatbot_bot_key
          if (typeof window.bebridgeNoteUrl === 'undefined')
            window.bebridgeNoteUrl = config.bebridge_note_url
          if (typeof window.bebridgeNotePluginKey === 'undefined')
            window.bebridgeNotePluginKey = config.bebridge_note_plugin_key

          if (typeof window.signInUrl === 'undefined') {
            const params = qs.stringify({
              response_type: 'code',
              client_id: config.oauth_client_id,
              redirect_uri: config.oauth_redirect_url,
              scope: config.oauth_scope,
            })
            window.signInUrl = `${config.oauth_authorization_endpoint}?${params}`
          }
        } else {
          console.error('Failed to fetch config:', config.error)
        }
      } catch (error) {
        console.error('Error fetching config:', error)
      }
    }

    fetchConfig()
  }, [])
  console.log('loading', loading)
  if (loading) return <Loading />

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>{children}</AuthProvider>
    </QueryClientProvider>
  )
}

export default Providers
