import axiosInstance from '@/shared/utils/axios'
import { convertCaseObject, ConvertType } from '@/shared/utils/convertString'
import { useQuery } from '@tanstack/react-query'
import { IShowRoomKDTMenu, IShowRoomKDTMenuResponse } from '../types'

export const fetchShowroomKDTMenuQuery = async () => {
  const resp = await axiosInstance.get<IShowRoomKDTMenuResponse>(
    `${window.apiEndpoint}/course/v1/showrooms/kdt-menu`,
  )

  return convertCaseObject(resp.data, ConvertType.CAMEL) as IShowRoomKDTMenu
}

export const useShowroomKDTMenuQuery = () => {
  return useQuery({
    queryKey: ['kdt-menu'],
    queryFn: () => fetchShowroomKDTMenuQuery(),
  })
}
