export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';')
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].trim()

    if (cookiePair.startsWith(name + '=')) {
      return cookiePair.substring(name.length + 1)
    }
  }
  return null
}

export const deleteCookie = (cookieName: string, domain: string) => {
  const isSecure = window.nodeEnv === 'production'
  document.cookie = `${cookieName}=; Max-Age=0; Path=/; Domain=${domain}; SameSite=Lax; Secure=${isSecure};`
}
