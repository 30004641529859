import React, { createContext, FC, useContext, useEffect, useMemo } from 'react'
import { v4 as uuid_v4 } from 'uuid'

import { useProfileQuery } from '@/features/auth/queries/useProfileQuery'
import { useRoleQuery } from '@/features/auth/queries/useRoleQuery'
import { IProfile } from '@/features/auth/types'
import qs from 'qs'
import { getCookie } from '../utils/cookies'
import { useQueryClient } from '@tanstack/react-query'
import Loading from '@/components/Loading'

export interface IAuthContextState {
  isSigned: boolean
  signInUrl: string
  profile: IProfile | null
  role: string
}

const AuthContext = createContext<IAuthContextState>({
  isSigned: false,
  signInUrl: '',
  profile: null,
  role: '',
})

export interface IAuthProviderProps {
  children: any
}

const AuthProvider: FC<IAuthProviderProps> = ({ children }) => {
  const { data: profile } = useProfileQuery()
  const { data: role } = useRoleQuery()
  const accessToken = getCookie('access_token')
  const queryClient = useQueryClient()
  console.log('window', window)
  const myRole = useMemo(() => {
    if (role && role.length > 0) {
      return role.find((v: string) => v === 'manager' || v === 'tutor') ?? 'trainee'
    }
    return 'trainee'
  }, [role])
  const signInUrlWithNonce = `${window.signInUrl}&nonce=${uuid_v4()}`
  console.log('signInUrlWithNonce', signInUrlWithNonce)

  useEffect(() => {
    if (profile !== null && accessToken === null) {
      queryClient.removeQueries({
        queryKey: ['profile'],
      })
      window.location.replace('/')
      return
    }
    if (profile === null && accessToken !== null) {
      queryClient.invalidateQueries({
        queryKey: ['profile'],
      })
      return
    }
  }, [accessToken, profile])

  if (typeof window === undefined) return <Loading />

  return (
    <AuthContext.Provider
      value={{ isSigned: profile !== null, signInUrl: signInUrlWithNonce, profile, role: myRole }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
export const useAuth = () => useContext(AuthContext)
