import { useQuery } from '@tanstack/react-query'
import QueryString from 'qs'

import { IShowRoom, IShowRoomResponse } from '../types'
import axiosInstance from '@/shared/utils/axios'
import { ConvertType, convertCaseList } from '@/shared/utils/convertString'

export const fetchShowRoomList = async (location: string, section: string, key?: string) => {
  const requestQueryString = QueryString.stringify({
    ...(key
      ? { filters: `location:${location}|section:${section}|filter_key:${key}` }
      : { filters: `location:${location}|section:${section}` }),
  })
  console.log('~~~~~~ process', process.env.NEXT_PUBLIC_API_ENDPOINT)
  const resp = await axiosInstance.get<IShowRoomResponse[]>(
    `${process.env.API_ENDPOINT}/course/v1/showrooms?${requestQueryString}`,
  )

  return convertCaseList(resp.data, ConvertType.CAMEL) as IShowRoom[]
}

export const useShowroomQuery = (location: string, section: string, key?: string) => {
  return useQuery({
    queryKey: ['showroom', section],
    queryFn: () => fetchShowRoomList(location, section, key),
    staleTime: 60 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
  })
}
