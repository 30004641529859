import {
  ChevronDownIcon,
  ChevronRightIcon,
  ClipboardDocumentCheckIcon,
  UserIcon,
  VideoCameraIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import styles from './Header.module.scss'
import useKDTNavList, { INavList } from '@/features/showroom/hooks/useKDTNavList'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import NebecaTestQuickItem from '../FloatingSidebar/NebecaTestQuickItem'
import UrgentKlassQuickItem from '../FloatingSidebar/UrgentKlassQuickItem'
import getUrgentKlassList from '@/features/klass/hooks/getUrgentKlassList'
import { IProfile } from '@/features/auth/types'
import useMyCurApplication from '@/features/apply/hooks/useMyCurApplication'
import { usePathname } from 'next/navigation'

interface IDropdownMobileProps {
  isSigned: boolean
  profile: IProfile | null
  ondropDownToggleHandler: () => void
  goToSignIn: () => void
}

const DropdownMobile = ({
  isSigned,
  profile,
  ondropDownToggleHandler,
  goToSignIn,
}: IDropdownMobileProps) => {
  const pathname = usePathname()
  const { navList } = useKDTNavList()
  const { urgentKlassList } = getUrgentKlassList()
  const { activeApplicationId, activeApplicationDeadline } = useMyCurApplication()
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<{ [key: string]: boolean }>({})

  const mobileNavList: INavList[] = isSigned ? [...navList, MY_PROFILE_MENU_LIST] : navList

  const toggleSubMenu = (key: string, hasSubMenu: boolean) => {
    if (hasSubMenu) {
      setIsSubMenuOpen(prev => ({
        ...prev,
        [key]: !prev[key],
      }))
    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      ondropDownToggleHandler()
    }

    handleRouteChange()

    return () => {
      setIsSubMenuOpen({})
    }
  }, [pathname])

  return (
    <div
      className={`fixed left-0 top-0 z-[75] w-full cursor-default overflow-y-auto bg-white px-5 pb-40 ${styles.dropdownMobileWrap}`}
    >
      <header className="sticky top-0 z-[200] flex w-full justify-end bg-white py-4">
        <button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            ondropDownToggleHandler()
          }}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
      </header>
      <main>
        {isSigned && profile && (
          <article className="space-y-4 pb-5">
            <h2 className="text-lg font-semibold">{profile.name} 님</h2>
            {activeApplicationId && (
              <Link
                href={`${window.baseUrl}/school/${activeApplicationId}/apply`}
                className="flex w-full items-center justify-between rounded-2xl border border-gray-800 px-5 py-4 shadow-[0px_8px_16px_0px_rgba(0,0,0,0.05)]"
              >
                <div className="flex items-center space-x-2.5">
                  <span className="rounded-full bg-red-500 px-2.5 py-1 text-[15px] font-semibold text-white">
                    D-{activeApplicationDeadline}
                  </span>
                  <p className="font-semibold">작성중인 지원서가 있어요!</p>
                </div>
                <ChevronRightIcon className="h-4 w-4 text-neutral-500" />
              </Link>
            )}
            <ul className="grid w-full grid-cols-3 gap-2">
              {POPULAR_MENU_LIST.map(({ title, icon, href }) => (
                <Link key={`자주사용하는_${title}`} href={href} as={href}>
                  <li className="box-border flex w-full flex-col items-center justify-center space-y-1.5 rounded-lg bg-neutral-50 py-4">
                    {icon}
                    <p className="text-sm">{title}</p>
                  </li>
                </Link>
              ))}
            </ul>
          </article>
        )}
        <ul>
          {mobileNavList.map(({ title, href, icon, subMenu }, idx) => {
            const key = `${title}_${idx}`
            const isOpen = isSubMenuOpen[key] || false

            return (
              <li key={key} onClick={() => toggleSubMenu(key, !!subMenu)}>
                <Link href={href} as={href} className="flex justify-between py-4">
                  <span className="font-semibold">{title}</span>
                  {subMenu && (
                    <span className={`${isOpen ? 'rotate-180' : ''} flex items-center`}>
                      {icon}
                    </span>
                  )}
                </Link>
                {subMenu && isOpen && (
                  <section className="space-y-6 py-3">
                    {subMenu.map(({ title, menuList }, subIdx) => (
                      <div className="space-y-1.5" key={`subMenu_${subIdx}`}>
                        {title && <p className="text-[13px] font-medium text-gray-400">{title}</p>}
                        <ul className="grid grid-cols-2 gap-1.5 place-self-center">
                          {menuList.map(({ title, href, isApply, isExternalLink }) => (
                            <li key={`서브메뉴_${title}`}>
                              <Link
                                href={href}
                                target={isExternalLink ? '_blank' : '_self'}
                                className="flex items-center gap-1 py-1.5"
                              >
                                <span className="inline-block truncate text-sm">{title}</span>
                                {isApply && (
                                  <span className="break-words break-keep rounded bg-gradient-to-r from-yellow-450 to-[#EC64A6] px-1.5 py-0.5 text-[11px] font-semibold text-white">
                                    모집중
                                  </span>
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </section>
                )}
              </li>
            )
          })}
        </ul>
        <section className="mt-3 grid grid-cols-[90px_90px_90px] gap-2 place-self-start">
          <NebecaTestQuickItem />
          <UrgentKlassQuickItem klassList={urgentKlassList} />
        </section>
      </main>
      <footer className={isSigned ? 'mt-5' : 'mt-6'}>
        {isSigned ? (
          <Link href="/oauth/logout" as="/oauth/logout">
            <span className="text-sm text-neutral-500 underline underline-offset-2">로그아웃</span>
          </Link>
        ) : (
          <button onClick={goToSignIn} className="rounded-full border border-gray-300 px-4 py-2">
            <span className="text-sm font-medium">로그인</span>
          </button>
        )}
      </footer>
    </div>
  )
}

export default DropdownMobile

const POPULAR_MENU_LIST = [
  {
    title: '강의목록',
    icon: <VideoCameraIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: `/my/courses`,
  },
  {
    title: '지원내역',
    icon: <ClipboardDocumentCheckIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: `/my/apply-status`,
  },
  {
    title: '내정보',
    icon: <UserIcon className="h-auto w-7 [&>path]:stroke-[1.5]" />,
    href: '/my/profile',
  },
]

const MY_PROFILE_MENU_LIST = {
  title: '계정 관리',
  href: '#',
  icon: <ChevronDownIcon className="w-4 shrink-0 group-open:rotate-180" />,
  subMenu: [
    {
      title: null,
      menuList: [
        {
          title: '내 정보 변경',
          href: '/my/profile',
        },
        {
          title: '비밀번호 변경',
          href: '/my/change-password',
        },
      ],
    },
  ],
}
