import useKDTNavList from '@/features/showroom/hooks/useKDTNavList'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useClickOutside } from '@mantine/hooks'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useState, useRef, useEffect } from 'react'
import styles from './Header.module.scss'

type OpenSubMenusType = {
  [key: string]: boolean
}

const GNB = () => {
  const pathname = usePathname()
  const { navList } = useKDTNavList()
  const [openSubMenus, setOpenSubMenus] = useState<OpenSubMenusType>({})
  const [hoveredSubMenu, setHoveredSubMenu] = useState<OpenSubMenusType>({})
  const timeoutRefs = useRef<{ [key: string]: NodeJS.Timeout }>({})
  const clickLockRef = useRef<{ [key: string]: boolean }>({})

  const ref = useClickOutside(() => {
    setOpenSubMenus({})
    setHoveredSubMenu({})
    Object.values(timeoutRefs.current).forEach(clearTimeout)
    Object.keys(clickLockRef.current).forEach(key => (clickLockRef.current[key] = false))
  })

  useEffect(() => {
    setOpenSubMenus({})
    setHoveredSubMenu({})
    Object.keys(clickLockRef.current).forEach(key => (clickLockRef.current[key] = false))
  }, [pathname])

  const handleMouseEnter = (key: string) => {
    if (timeoutRefs.current[key]) {
      clearTimeout(timeoutRefs.current[key])
    }
    if (!clickLockRef.current[key]) {
      setHoveredSubMenu(prev => ({ ...prev, [key]: true }))
    }
  }

  const handleMouseLeave = (key: string) => {
    timeoutRefs.current[key] = setTimeout(() => {
      setHoveredSubMenu(prev => {
        const newState = { ...prev }
        delete newState[key]
        return newState
      })
    }, 100)
  }

  return (
    <nav>
      <ul className="group hidden gap-4 lg:flex" ref={ref}>
        {navList.map(({ title, href, icon, subMenu }) => {
          const key = `${title}_${href}`
          const isOpen = openSubMenus[key] || hoveredSubMenu[key] || false

          const pathName = pathname.split('/')[1]
          const hrefName = href.split('/')[1]

          return (
            <li
              key={key}
              className="group relative list-none"
              onMouseEnter={() => subMenu && handleMouseEnter(key)}
              onMouseLeave={() => subMenu && handleMouseLeave(key)}
            >
              <div className="cursor-pointer list-none group-open:pb-1">
                <div
                  className={`${
                    subMenu && isOpen ? 'bg-[#F5F5F5]' : ''
                  } flex w-full items-center justify-between gap-x-1.5 rounded-lg px-3 py-2.5`}
                >
                  <Link href={href} as={href}>
                    <div
                      className={`flex cursor-pointer text-base font-semibold ${
                        pathName === hrefName ? 'text-yellow-450' : 'text-trueGray-800'
                      } `}
                    >
                      <span className="group-open:text-red">{title}</span>
                    </div>
                  </Link>
                  {subMenu && icon && (
                    <ChevronDownIcon
                      className={`${
                        isOpen ? 'rotate-180 transform' : ''
                      } h-4 w-4 shrink-0 grow-0 text-gray-500`}
                    />
                  )}
                </div>
              </div>
              {subMenu && isOpen && (
                <div className="absolute z-100 pt-5" onClick={event => event.stopPropagation()}>
                  <div className="flex w-auto flex-row gap-6 rounded-xl bg-white p-6 shadow-[6px_6px_60px_-26px_rgba(0,0,0,0.20)]">
                    {subMenu.map(({ title, menuList }, subIdx) => (
                      <div key={`서브메뉴_${subIdx}`}>
                        {title && <p className="mb-4 text-sm font-medium text-gray-400">{title}</p>}
                        <ul className="flex min-w-[152px] flex-col gap-3 bg-white">
                          {menuList.map(({ title, href, isApply, isExternalLink }) => (
                            <li key={`서브메뉴_${title}`}>
                              <Link href={href} target={isExternalLink ? '_blank' : '_self'}>
                                <div
                                  className={`${styles.link_hover} flex flex-row items-center justify-start gap-1.5`}
                                >
                                  <span className="inline-block truncate text-base font-medium text-trueGray-800">
                                    {title}
                                  </span>
                                  {isApply && (
                                    <span className="flex h-5 flex-row items-center justify-center break-words break-keep rounded bg-gradient-to-r from-yellow-450 to-[#EC64A6] px-1.5 text-xs font-semibold text-white">
                                      모집중
                                    </span>
                                  )}
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default GNB
