import { useQuery } from '@tanstack/react-query'
import { convertCaseList, ConvertType } from '@/shared/utils/convertString'

import axiosInstance from '@/shared/utils/axios'

export const fetchTermsOfService = async () => {
  const resp = await axiosInstance.get(`${window.apiEndpoint}/me/terms-of-service`)
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const useTermsOfServiceQuery = () => {
  return useQuery({
    queryKey: ['terms-of-service'],
    queryFn: () => fetchTermsOfService(),
    initialData: null,
  })
}
